import React, { useRef, useState } from "react";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SingleSelector from "../GenericComponents/SingleSelector.js";

function TrainerSearchFields({ onSearch }) {
    const nameRef = useRef('');
    const textRef = useRef('');
    const [subtype, setSubtype] = useState('');
    const [subtype2, setSubtype2] = useState('');

    const subtypeOptions = ["Any", "Supporter", "Item", "Stadium", "Tool"];
    const subtype2Options = ["Any", "Fusion Strike", "Single Strike", "Rapid Strike", "Ancient", "Future"];

    function handleSearch() {
        const searchParams = {
            name: nameRef.current.value,
            subtype: subtype === 'Any' ? '' : subtype,
            subtype2: subtype2 === 'Any' ? '' : subtype2,
            text: textRef.current.value
        };

        onSearch(searchParams, 1);
    }

    return (
        <div>
            <TextField inputRef={nameRef} label="Name" variant="outlined" />
            <br /><br />

            <SingleSelector
                label="Subtype"
                values={subtypeOptions}
                onChange={setSubtype}
                selectedValue={subtype}
            />
            <br /><br />

            <SingleSelector
                label="Subtype 2"
                values={subtype2Options}
                onChange={setSubtype2}
                selectedValue={subtype2}
            />
            <br /><br />

            <TextField inputRef={textRef} label="Text" variant="outlined" />
            <br /><br />

            <Button variant="contained" onClick={handleSearch}>Search</Button>
        </div>
    );
}

export default TrainerSearchFields;