import React, { useState } from "react";
import EnergySearchFields from "./EnergySearchFields.js"
import PokemonSearchFields from "./PokemonSearchFields.js"
import TrainerSearchFields from "./TrainerSearchFields.js"
import CardDisplay from "./CardDisplay.js"
import { Container, Typography, Box, Tabs, Tab } from '@mui/material';

const RESULTS_PER_PAGE = 20;

function MainBody() {

    const [tabIndex, setTabIndex] = useState(0);
	const [cardData, setCardData] = useState([{}])
    const [currentPage, setCurrentPage] = useState(1);
    const [prevSearchParams, setPrevSearchParams] = useState({});
    const cardTypes = ["Pokemon", "Trainer", "Energy"];

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
		setCardData([{}]);
        setCurrentPage(1);
        setPrevSearchParams({});
    };

    // onSearch is passed to the search fields components
    // and runs the query against the backend.
    function onSearch(searchParams, page = currentPage) {
        var url = `/query/${cardTypes[tabIndex].toLowerCase()}?page=${page}&limit=${RESULTS_PER_PAGE}`;  // Include pagination parameters
        
        setPrevSearchParams(searchParams);

        // Append other search parameters to the URL
        for (const [key, value] of Object.entries(searchParams)) {
            url += `&${key}=${value}`;
        }

        fetch(url).then(
            res => res.json()
        ).then(
            data => {
                setCardData(data)
            }
        );
    }

    function onPageChange(newPage) {
        setCurrentPage(newPage);
        onSearch(prevSearchParams, newPage)
    }

    return (
        <Container>
            <Typography variant="h4" align="center" gutterBottom>
                Flower Selecting!
            </Typography>

            <Box my={4}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    centered
                >
                    <Tab label="Pokemon" />
                    <Tab label="Trainer" />
                    <Tab label="Energy" />
                </Tabs>
            </Box>

            {tabIndex === 0 && <PokemonSearchFields onSearch={onSearch} />}
            {tabIndex === 1 && <TrainerSearchFields onSearch={onSearch} />}
            {tabIndex === 2 && <EnergySearchFields onSearch={onSearch} />}

            <Box mt={4}>
                <CardDisplay cardData={cardData} onPageChange={onPageChange}/>
            </Box>
        </Container>
    );
}

export default MainBody;