// This MultiSelectFieldDuplicates component is a modified version of the MultiSelectField component.
// It Allows to select duplicate options from the dropdown menu. (Used for Attack colors cost.)
import React, { useState } from 'react';
import { TextField, Box, Chip, Popover, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MultiSelectFieldDuplicates = ({ label, placeholder, options, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  // eslint-disable-next-line
  const [isFocused, setIsFocused] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsFocused(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsFocused(false);
  };

  const handleToggle = (item) => {
    const updatedSelection = [...selectedItems, item];
    setSelectedItems(updatedSelection);
    onSelect(updatedSelection);
  };

  const handleReset = () => {
    setSelectedItems([]);
    onSelect([]);
    handleClose();
  };

  const handleOptionHover = (option) => {
    setHoveredOption(option);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <TextField
        onClick={handleClick}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setTimeout(() => {
            if (!isDropdownOpen) {
              setIsFocused(false);
            }
          }, 200); 
        }}
        placeholder={selectedItems.length === 0 ? placeholder : ''}
        label={(selectedItems.length > 0) ? label : ''}
        variant="outlined"
        fullWidth
        InputProps={{
          readOnly: true,
          inputProps: {
            id: 'multiselect-input',
          },
          startAdornment: (
            <>
              {selectedItems.map((selectedItem, index) => (
                <Chip
                  key={index}
                  label={selectedItem}
                  color="primary"
                  size="small"
                  sx={{ margin: '2px' }}
                />
              ))}
            </>
          ),
          endAdornment: selectedItems.length > 0 && (
            <IconButton onClick={handleReset} size="small" sx={{ marginLeft: '8px' }}>
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onEntered={() => setIsDropdownOpen(true)}
        onExited={() => setIsDropdownOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={2} minWidth="200px" sx={{
          border: '1.5px solid #ccc', // Border styles
          borderRadius: '4px', // Border radius
        }}>
          {options.map((option) => (
            <Box
              key={option}
              marginTop={1}
              sx={{
                cursor: 'pointer',
                color: selectedItems.includes(option) ? 'blue' : 'inherit',
                backgroundColor: hoveredOption === option ? '#e0e0e0' : 'inherit',
              }}
              onMouseEnter={() => handleOptionHover(option)}
              onMouseLeave={() => handleOptionHover(null)}
              onClick={() => handleToggle(option)}
            >
              {option}
            </Box>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default MultiSelectFieldDuplicates;
