import React from "react";
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

const style = {
	position: 'absolute',
	top: '50%',
	left: '53%',
	transform: 'translate(-50%, -50%)',
};

function Card(props) {

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	return (
		<>
			<img onClick={handleOpen} src={props.src_small} alt={`Card ${props.src_small}`} />
			<Modal open={open} onClose={handleClose}>
				<Box sx={style}>
					<img style={{ width: "80%", height: "80%" }} src={props.src_large} alt={`Card ${props.src_large}`} />
				</Box>
			</Modal>
		</>
	);
}



export default Card;