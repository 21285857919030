import React from "react";
import MainBody from './MainBody/MainBody.js'

function App() {
  return (

    <div>
      <MainBody></MainBody>
    </div>

  );
}

export default App;
