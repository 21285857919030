import React, { useRef, useState } from "react";
import SingleSelector from "../GenericComponents/SingleSelector.js"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Multiselect } from 'multiselect-react-dropdown';

function EnergySearchFields(props) {
    const nameRef = useRef('');
    const effectTextRef = useRef('');
    const subtypeOptions = ["Any", "Basic", "Special"];

    const typeOptions = [
        { name: "Fire", id: 1 },
        { name: "Water", id: 2 },
        { name: "Psychic", id: 3 },
        { name: "Grass", id: 4 },
        { name: "Lightning", id: 5 },
        { name: "Darkness", id: 6 },
        { name: "Metal", id: 7 },
        { name: "Fighting", id: 8 }
    ];


    const [subtype, setSubtype] = useState('');
    const [selectedTypes, setSelectedTypes] = useState([]); // State for selected types

    function handleSearch() {
        const searchParams = {
            name: nameRef.current.value,
            rules: effectTextRef.current.value,
            subtype: subtype === 'Any' ? '' : subtype,
            types: selectedTypes.map(type => type.name).join(','),
        };

        props.onSearch(searchParams, 1);
    }

    return (
        <div>
            <TextField inputRef={nameRef} label="Name" variant="outlined" />
            <br /><br />

            <SingleSelector
                label="Subtype"
                values={subtypeOptions}
                onChange={setSubtype}
                selectedValue={subtype}
            />
            {subtype === "Basic" && (
                <>
                    <br /><br />
                    <Multiselect
                        options={typeOptions} // Options to display in the dropdown
                        selectedValues={selectedTypes} // Preselected value to persist in dropdown
                        onSelect={setSelectedTypes} // Function will trigger on select event
                        onRemove={setSelectedTypes} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder="Select Energy Type" // Placeholder for the dropdown search input
                    />
                </>
            )}
            <br /><br />

            <TextField inputRef={effectTextRef} label="Effect Text" variant="outlined" />
            <br /><br />

            <Button variant="contained" onClick={handleSearch}>Search</Button>
        </div>
    );

}

export default EnergySearchFields;