import React from "react";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function SingleSelector(props) {

	const [val, setVal] = React.useState(props.values[0]);

	const handleChange = (event) => {
		const value = event.target.value;
		setVal(value);
		if (typeof props.onChange !== "undefined") {
			props.onChange(value);
		}
	};

	return (
		<>
			<FormControl variant="filled" sx={{minWidth: 135 }}>
				<InputLabel id={props.label + "label"}>{props.label}</InputLabel>
				<Select
					labelId={props.label + "label"}
					id="selector"
					value={val}
					label="selector"
					onChange={handleChange}
				>
					{props.values.map((value, i) => (
						<MenuItem key={value} value={value}>{value}</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	);
}

export default SingleSelector;
