import React from "react";
import {Box, Button, ImageList, ImageListItem} from '@mui/material';
import Card from "./Card.js"


function CardDisplay({ cardData, onPageChange }) {
    // check if we've received data yet, to see if we display the buttons or not
    const isPaginated = cardData.currentPage !== undefined && cardData.totalPages !== undefined;
    const { currentPage, totalPages } = cardData;

    function handleNext() {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
        window.scrollTo(0, 0)
    }

    function handlePrevious() {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
        window.scrollTo(0, 0)
    }

    const images = isPaginated ? cardData.results : cardData.images
    return (
        <Box>
            <Box>
                <ImageList cols={4}>
                    {images && images.map((value, i) => (
                        <ImageListItem key={i}>
                            <Card src_small={value.small} src_large={value.large} alt={`Card ${i}`} />
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
            {isPaginated && (
                <Box display="flex" justifyContent="center" my={2}>
                    <Button onClick={handlePrevious} disabled={cardData.currentPage <= 1}>
                        Previous
                    </Button>
                    <Button onClick={handleNext} disabled={cardData.currentPage >= cardData.totalPages}>
                        Next
                    </Button>
                </Box>
            )}
        </Box>
    );
}



export default CardDisplay;