import React, {useRef, useState} from "react";
import SingleSelector from "../GenericComponents/SingleSelector.js"
import { Grid, TextField, Button, Box } from '@mui/material';
import MultiSelectFieldDuplicates from "../GenericComponents/MultiSelectFieldDuplicates.js";
import MultiSelectField from "../GenericComponents/MultiSelectField.js";

function PokemonSearchFields(props) {

	const stageValues = ["Any", "Basic", "Stage 1", "Stage 2"]
	const subtypeValues = ["Any", "ex", "V", "VMAX", "VSTAR", "V-Union",
		"Single Strike", "Fusion Strike", "Rapid Strike", "Tera", "Future", "Ancient", "Radiant"]
	const types = ["Any", "Fire", "Water", "Lightning", "Grass", "Fighting", "Psychic", "Colorless",
		"Darkness", "Metal", "Dragon", "Fairy"]
	const hpmods = ["=", ">=", "<="]
    const prizeValues = [ "Any" , "1", "2" , "3"]

	// found this easier and "maybe" more efficient than using abunch of useStates.
	// im sure there's a cleaner way of handling all these different variables with a single object but
	// idgaf right now
	const nameRef = useRef('');
	const hpRef = useRef('');
	const attackDamageRef = useRef('');
	const attackCostRef = useRef('');
	const attackTextRef = useRef('');
	const abilityRef = useRef('');
	const retreatRef = useRef('');
    

    const [prize, setPrize] = useState('Any');
	const [stage, setStage] = useState('Any');
	//const [subtype, setSubtype] = useState('Any');
	const [mod, setMod] = useState('=');
	const [type, setType] = useState('Any');
	const [weakness, setWeakness] = useState('Any');

    //Multi-Select Subtypes
    const [selected_Subtypes, setSelectedSubtypes] = useState([]);



    //Multi-Select Attack Colors
    const initialAttackColors = ['Fire', 'Water', 'Lightning', 'Grass', 'Fighting', 'Psychic', 'Colorless', 'Darkness', 'Metal', 'None'];
    const [attack_colors] = useState(initialAttackColors);
    const [attack_cost_colors, setSelectedAttackColors] = useState([]);



	function onSearch() {
		// create a dict of all the values 
		const dict = {}

		// text fields are handled with refs
		dict["name"] = nameRef.current.value;
		dict["hp"] = hpRef.current.value;
		dict["attack_damage"] = attackDamageRef.current.value;
		dict["attack_cost"] = attackCostRef.current.value;
		dict["attack_text"] = attackTextRef.current.value;
		dict["ability_text"] = abilityRef.current.value;
		dict["retreat"] = retreatRef.current.value;
		
		// selectors are handled with states
		dict["stage"] = (stage === 'Any') ? "" : stage
		//dict["subtype"] = (subtype === 'Any') ? "" : subtype
		dict["type"] = (type === 'Any') ? "" : type
		dict["weakness"] = (weakness === 'Any') ? "" : weakness
        dict["prize"] = (prize === 'Any') ? "" : prize

		dict["hp_mod"] = mod

        //Multi-Select Subtypes
        dict["selected_Subtypes"] = selected_Subtypes;
        dict["attack_cost_colors"] = attack_cost_colors;

		props.onSearch(dict, 1);
	}


    //Multi-Select Subtypes styling.
    const customStyles = {
        searchBox: {
          fontSize: '15px',
          minHeight: '55px',
        },
        chips: {
          background: '#1976D2',
        },
        option: {
          color: 'black',
          fontSize: '15px',
        },
      };


    return (
        
        <Box alignContent="center" justifyContent="center" display="flex">
            <Grid container spacing={2} sx={{maxWidth:600}}>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        inputRef={nameRef}
                        label="Name"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={3}>
                    <SingleSelector
                        label="Stage"
                        values={stageValues}
                        onChange={setStage}
                    />
                </Grid>

				<Grid item xs={3}>
                    <SingleSelector
                        label="Type"
                        values={types}
                        onChange={setType}
                    />
                </Grid>

                <Grid item xs={3}>
                    <SingleSelector
                        label="HP Modifier"
                        values={hpmods}
                        onChange={setMod}
                    />
                </Grid>

                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        inputRef={hpRef}
                        label="HP"
                        type="number"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={3}>
                    <SingleSelector
                        label="Weakness"
                        values={types}
                        onChange={setWeakness}
                    />
                </Grid>

                <Grid item xs={3}>
                    <SingleSelector
                        label="Prizes Granted"
                        values={prizeValues}
                        onChange={setPrize}
                    />
                </Grid>

                <Grid item xs={3} >
                    <TextField
                        fullWidth
                        inputRef={attackDamageRef}
                        label="Attack Damage"
                        type="number"
                        variant="outlined"
                    />
                </Grid>

				<Grid item xs={3}>
                    <TextField
                        fullWidth
                        inputRef={attackCostRef}
                        label="Attack Cost"
                        type="number"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        inputRef={attackTextRef}
                        label="Attack Text"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        inputRef={abilityRef}
                        label="Ability Text"
                        variant="outlined"
                    />
                </Grid>
                

                <Grid item xs={6} >
                    <MultiSelectField
                        label="Subtypes"
                        placeholder="Subtypes"
                        isObject={false}
                        options={subtypeValues}
                        onSelect={setSelectedSubtypes}
                        style={customStyles}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        inputRef={retreatRef}
                        label="Max Retreat Cost"
                        type="number"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={8} >
                    <MultiSelectFieldDuplicates
                        label="Attack Energy Types"
                        placeholder="Attack Energy Types"
                        options={attack_colors}
                        onSelect={setSelectedAttackColors}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={onSearch}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PokemonSearchFields;